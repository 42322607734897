import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { openTab } from '../utils/open-tab';
import { flexColumn } from './common/styled';
import { MahButton } from './common/MahButton';

const SCardActionArea = styled(CardActionArea)`
  && {
    ${flexColumn};
    align-items: center;
  }
`;

const StyledCard = styled(Card)`
  &&& {
    max-width: 350px;
    ${flexColumn};
    align-items: center;

    & > img {
      vertical-align: -webkit-baseline-middle;
    }
  }
`;

const B = styled.div`
  && {
    background: white;
    position: absolute;
    margin: calc(var(--medium) * -1);
    padding: var(--small);
    color: black;
    z-index: 2;
    border: 2px solid white;
    border-radius: 50%;
  }
`;

const PointyDowny = styled.div`
  && {
    width: 0;
    height: 0;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;

    border-top: 20px solid grey;
    position: relative;
    z-index: 1;
    top: 20px;
  }
`;

const SThumbUpIcon = styled(ThumbUpIcon)`
  color: brown;
  position: relative;
  top: 5px;
`;

const Asd = ({ productNumber }) => (
  <>
    <B>
      <Typography>
        <SThumbUpIcon />
        {` #${productNumber}
        Testvinner`}
      </Typography>
    </B>
    <PointyDowny />
  </>
);

const HighlightProduct = ({
  productName,
  image,
  affiliateUrl,
  productNumber,
}) => (
  <StyledCard>
    <Asd productNumber={productNumber} />
    <SCardActionArea onClick={e => openTab(e, affiliateUrl)}>
      <CardContent>
        <Typography gutterBottom variant="h6" component="p">
          {productName}
        </Typography>
      </CardContent>
      {image && <GatsbyImage image={getImage(image)} />}
    </SCardActionArea>
    <CardActions>
      <MahButton url={affiliateUrl} />
    </CardActions>
  </StyledCard>
);

export default HighlightProduct;
