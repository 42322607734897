import React from 'react';
import Typography from '@mui/material/Typography';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { useMediaQuery, Paper } from '@mui/material';

const H1 = ({ children }) => {
  const under420 = useMediaQuery('(max-width:420px');

  return (
    <>
      {under420 ? (
        <Typography variant="h5" component="h1">
          {children}
        </Typography>
      ) : (
        <Typography variant="h4" component="h1">
          {children}
        </Typography>
      )}
    </>
  );
};

const HeadingBackground = styled.div`
  position: absolute;
  background: rgba(253, 255, 244, 0.75);
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  padding: var(--small);

  @media (min-width: 420px) {
    margin: var(--medium);
  }
`;

const SContainer = styled.div`
  position: relative;
`;

const ReviewPageHeader = ({ pageTitle, featureImage }) => {
  return (
    <SContainer as={Paper}>
      <GatsbyImage image={getImage(featureImage)} />
      <HeadingBackground as={Paper}>
        <H1>{pageTitle}</H1>
      </HeadingBackground>
    </SContainer>
  );
};

export default ReviewPageHeader;
