// https://cdon.no/sport/elsykkel-vtt-overvolt-am600i-herre-2019-sort-gra-lapierre-p47659484
// https://clk.tradedoubler.com/click
// ?p=116
// &a=3137481
// &url=https%3A%2F%2Fcdon.no%2Fsport%2Felsykkel-vtt-overvolt-am600i-herre-2019-sort-gra-lapierre-p47659484

/**
 * Creates a url for a tradedoubler merchant
 *
 * @param {string | number} channel - channel id
 * @param {string | number} program - program / merchant id
 * @param {string } productUrl - Url to the product to promote
 * @param {string=} epi - custom string used for A/B testing etc
 *
 * @returns {string} - a url used as a href attribute
 */
const makeTradedoublerUrl = (channel, program, productUrl, epi) => {
  if (!channel || !program || !productUrl) {
    throw new Error('Missing input');
  }
  const base = 'https://clk.tradedoubler.com/click';
  const programString = `p=${program}`;
  const channelString = `&a=${channel}`;
  const epiString = epi ? `&epi=${epi}` : '';
  const productString = `&url=${encodeURIComponent(productUrl)}`;
  return `${base}?${programString}${channelString}${epiString}${productString}`;
};

export { makeTradedoublerUrl };
