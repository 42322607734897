import React from 'react';
import styled from '@emotion/styled';
import HighlighProduct from '../HighlightProduct';
import MobileStepper from '@mui/material/MobileStepper';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Heading2 } from '../common/Heading2';
import { LeftButton, RightButton } from './StepperButtons';
import { Paper } from '@mui/material';
import { flexColumn } from '../common/styled';
const SContainer = styled(Container)`
  &&& {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
const Wrapper = styled.div`
  &&& {
    ${flexColumn};
    align-items: center;

    background: #012c43;
    margin-bottom: 14px;
    padding-bottom: 14px;
  }
`;
const STypography = styled(Typography)`
  && {
    color: #fff;
    padding-bottom: 14px;
    margin: 0 14px 0 14px;
  }
`;

const ProductHighlightHeader = ({ products = [] }) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const matchesLess = useMediaQuery('(min-width:650px');

  if (products.length === 0) return <></>;

  const allProducts = products.map((p, i) => {
    return (
      <HighlighProduct
        key={p.key}
        productName={p.name}
        id={p.id}
        image={p.image}
        affiliateUrl={p.affiliateUrl}
        productNumber={i + 1}
      />
    );
  });
  const maxSteps = allProducts.length;
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <Wrapper as={Paper}>
      <HighlightHeading fancy={false} id="beste-produkt">
        Testvinnere
      </HighlightHeading>
      <STypography variant={matchesLess ? 'h4' : 'h5'} component="h3">
        <span role="img" aria-label="stjerner">
          ⭐⭐⭐⭐⭐
        </span>
      </STypography>
      <Div>{allProducts[activeStep]}</Div>
      <SContainer maxWidth="md">
        <LeftButton
          onClick={handleBack}
          label="Forrige"
          activeStep={activeStep}
        />
        <SMobileStepper
          position="static"
          variant="dots"
          steps={maxSteps}
          activeStep={activeStep}
        />
        <RightButton
          onClick={handleNext}
          label="Neste"
          activeStep={activeStep}
          maxSteps={maxSteps}
        />
      </SContainer>
    </Wrapper>
  );
};

export default ProductHighlightHeader;

const HighlightHeading = styled(Heading2)`
  color: #fff;
  padding: var(--small);
  margin: 0 14px 0 14px;
`;

const Div = styled.div`
  margin-top: 48px;
`;
const SMobileStepper = styled(MobileStepper)`
  margin-top: var(--small);
`;
