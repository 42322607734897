import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { MahButton } from '../common/MahButton';
import { ProductImage } from '../common/ProductImage';
import { openTab } from '../../utils/open-tab';
import { flexColumn } from '../common/styled';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const boxVariants = {
  hidden: { opacity: 0, x: -900 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.1,
      duration: 0.2,
    },
  },
};

const Row1 = styled.div`
  flex-basis: 46px;
  flex-shrink: 0;
  height: 100%;

  display: flex;
  justify-content: center;
`;

const RowCta = styled.div`
  flex-basis: 150px;
  flex-shrink: 0;

  display: flex;
  justify-content: center;
`;

const Wrapper = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--grey);
  background: white;
  .navn-bilde {
    flex-grow: 1;
    border-left: 1px solid var(--grey);
    border-right: 1px solid var(--grey);

    padding: var(--small);

    ${flexColumn};
    align-items: center;
  }

  .gatsby-image-wrapper {
    max-height: 100px;
    max-width: 100px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;
  border-left: 1px solid var(--grey);
  border-top: 1px solid var(--grey);
  border-right: 1px solid var(--grey);

  div {
    p {
      font-weight: bold;
    }
  }
`;

const Grow = styled.div`
  flex-grow: 1;
  border-left: 1px solid var(--grey);
  border-right: 1px solid var(--grey);

  display: flex;
  justify-content: center;
`;

const SimpleItem = ({ number, image, productName, affiliateUrl }) => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: true });

  React.useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
    if (!inView) {
      controls.start('hidden');
    }
  }, [controls, inView]);
  return (
    <div ref={ref}>
      <Wrapper variants={boxVariants} initial="hidden" animate={controls}>
        <Row1>
          <Typography fontWeight={'bold'}>#{number}</Typography>
        </Row1>
        <div className="navn-bilde">
          {image && (
            <motion.div whileHover={{ scale: 1.1 }}>
              <ProductImage
                fixed={image}
                alt=""
                onClick={e => openTab(e, affiliateUrl)}
              />
            </motion.div>
          )}

          <Typography gutterBottom variant="h6" component="p">
            {productName}
          </Typography>
        </div>
        <RowCta>
          <motion.div whileHover={{ scale: 1.1 }}>
            <MahButton url={affiliateUrl} />
          </motion.div>
        </RowCta>
      </Wrapper>
    </div>
  );
};

const SimpleTable = ({ products }) => {
  if (products.length === 0) return null;
  return (
    <div>
      <Header>
        <Row1>
          <Typography>Rang</Typography>
        </Row1>
        <Grow>
          <Typography>Produktnavn</Typography>
        </Grow>
        <RowCta>
          <Typography>Butikk</Typography>
        </RowCta>
      </Header>
      {products.map((p, i) => (
        <SimpleItem
          key={i}
          number={i + 1}
          image={p.image}
          productName={p.name}
          affiliateUrl={p.affiliateUrl}
        />
      ))}
    </div>
  );
};

export default SimpleTable;
