/**
 * Creates a url for a adrecord merchant
 *
 * @param {string | number} channel - channel id
 * @param {string | number} program - program / merchant id
 * @param {string } productUrl - Url to the product to promote
 * @param {string=} epi - custom string used for A/B testing etc
 *
 * @returns {string} - a url used as a href attribute
 */
const makeAdrecordUrl = (channel, program, productUrl, epi) => {
  if (!channel || !program || !productUrl) {
    throw new Error('Missing input');
  }
  const base = 'https://click.adrecord.com';
  const channelString = `c=${channel}`;
  const programString = `&p=${program}`;
  const epiString = epi ? `&epi=${epi}` : '';
  const productString = `&url=${productUrl}`;
  return `${base}?${channelString}${programString}${epiString}${productString}`;
};

export { makeAdrecordUrl };
