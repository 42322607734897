import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import Layout from '../components/layout';
import { options } from '../utils/richTextOptions';
import SEO from '../components/seo';
import ReviewPageHeader from '../components/ReviewPageHeader';
import ProductHighlightHeader from '../components/ProductHighlighter/ProductHighlightHeader';
import { graphql } from 'gatsby';
import TOC from '../components/TOC';
import SimpleTable from '../components/SimpleTable/SimpleTable';
import {
  productHelper,
  isProductReview,
  refToContentfulProductReview,
  getFirstProduct,
} from '../utils/reviewPageUtils';
import { BottomCta } from '../components/BottomCta';

const ReviewPage = ({ data }) => {
  const {
    pageTitle,
    contentReferences,
    meta: { metaTitle, metaDescription },
    featureImage,
  } = data.contentfulReviewPage;
  const affiliatedMerchants =
    data &&
    data.allContentfulAffiliatedMerchant &&
    data.allContentfulAffiliatedMerchant.nodes;
  const products = productHelper(contentReferences, affiliatedMerchants);
  const stProducts = productHelper(contentReferences, affiliatedMerchants);

  const content = contentReferences.map((ref, i) => {
    if (isProductReview(ref.__typename)) {
      return refToContentfulProductReview(ref, i, affiliatedMerchants);
    }
    return renderRichText(ref.richTextBlock, options);
  });

  const firstProduct = getFirstProduct(contentReferences, affiliatedMerchants);

  return (
    <Layout>
      <SEO metaDescription={metaDescription} title={metaTitle} />
      <ReviewPageHeader featureImage={featureImage} pageTitle={pageTitle} />
      <TOC />
      <SimpleTable products={stProducts} />
      {content}
      <ProductHighlightHeader
        products={products}
        affiliatedMerchants={affiliatedMerchants}
      />
      {firstProduct ? (
        <BottomCta url={firstProduct.affiliateUrl} image={firstProduct.image} />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!) {
    contentfulReviewPage(slug: { eq: $slug }) {
      __typename
      contentful_id
      featureImage {
        gatsbyImageData(quality: 90, width: 720, layout: CONSTRAINED)
      }
      pageTitle
      meta {
        metaTitle
        metaDescription
      }
      contentReferences {
        __typename
        ... on ContentfulContentBlock {
          contentful_id
          __typename
          sys {
            revision
            type
            contentType {
              sys {
                id
                linkType
                type
              }
            }
          }
          id
          richTextBlock {
            raw
            references {
              __typename
              ... on ContentfulCallToActionButton {
                __typename
                text
                to
                contentful_id
              }
              ... on ContentfulPage {
                __typename
                id
                slug
                contentful_id
              }
              ... on ContentfulCalculatorPage {
                __typename
                id
                slug
                contentful_id
              }
              ... on ContentfulReviewPage {
                __typename
                id
                slug
                contentful_id
              }
              ... on ContentfulImage {
                url {
                  url
                }
                __typename
                contentful_id
                altTag
                image {
                  gatsbyImageData(width: 1600)
                }
              }
            }
          }
        }
        ... on ContentfulProductReview {
          __typename
          contentful_id
          sys {
            revision
            type
            contentType {
              sys {
                id
                linkType
                type
              }
            }
          }
          richTextBlock {
            raw
          }
          id
          active
          product {
            url {
              url
            }
            name
            imageReference {
              altTag
              image {
                gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
    allContentfulAffiliatedMerchant {
      nodes {
        merchantIdentifier
        name
        merchantUrl
        customDomain
        affiliateProgram {
          name
          affiliateId
        }
      }
    }
  }
`;
export default ReviewPage;
