import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Typography, Button, IconButton } from '@mui/material'; // TODO
import { openTab } from '../utils/open-tab';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import useMediaQuery from '@mui/material/useMediaQuery';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import CloseIcon from '@mui/icons-material/Close';

const Inner = styled.div`
  display: flex;
  height: 100%;
  padding: var(--small);
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: bold;
  }

  svg {
    font-size: 4em;
  }

  .gatsby-image-wrapper {
    max-height: 50px !important;
    max-width: 60px !important;
  }

  div {
    display: flex;
    align-items: center;

    p {
      padding-left: var(--small);
    }
  }
`;

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  border-top: 1px solid var(--color-primary);

  background: #fff;
  z-index: 99;
  transform: translate3d(0, 72px, 1px);
  transition: transform 0.5s;

  transform: ${({ show }) =>
    show ? 'translate3d(0, 0, 1px)' : 'translate3d(0, 72px, 1px)'};

  .close-button {
    position: absolute;
    bottom: 46px;
    left: 10px;
    z-index: 4;
    width: 26px;
    height: 26px;
    background: white;
    border: 1px solid var(--color-primary);
  }
`;

const textMobile = 'Hastverk? Vårt valg:';
const textWide = 'Hastverk? Redaktørens valg:';

export const BottomCta = ({ image, url }) => {
  const [scroll, setScroll] = useState();
  const [show, setShow] = useState(false);
  const [closeClicked, setCloseClicked] = useState(false);

  const matches = useMediaQuery('(min-width:400px)');
  const desktop = useMediaQuery('(min-width:1000px)');

  useEffect(() => {
    if (closeClicked) {
      setShow(false);
    } else {
      setShow(scroll > 0.15);
    }
  }, [closeClicked, scroll]);

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;
    setScroll(scrollTop / (docHeight - winHeight));
  };
  const handleCloseClicked = () => {
    window.removeEventListener('scroll', handleScroll);
    setCloseClicked(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Wrapper show={show}>
      <Inner>
        <Typography color="primary">
          {matches ? textWide : textMobile}
        </Typography>
        {matches && <ArrowRightAltIcon color="primary" />}
        <div>
          {image && <GatsbyImage image={getImage(image)} />}
          {desktop && <Typography color="primary">Testvinner</Typography>}
        </div>
        <Button
          size={desktop ? 'large' : 'small'}
          variant="contained"
          color="secondary"
          onClick={e => openTab(e, url)}
        >
          Se her
        </Button>
      </Inner>
      <IconButton
        className="close-button"
        color="secondary"
        aria-label="lukk produkt footer"
        component="span"
        onClick={handleCloseClicked}
      >
        <CloseIcon color="primary" />
      </IconButton>
    </Wrapper>
  );
};
