import { makeAdrecordUrl } from './adrecord';
import { makeAdtractionUrl } from './adtraction';
import { makeTradetrackerUrl } from './tradetracker';
import { makeTradedoublerUrl } from './tradedoubler';
import { makeAwinUrl } from './awin';
// import { isXlife, makeXLifeUrl } from './xlife';
import { makeDaisyconUrl } from './daisycon';
import { makePartnerAdsUrl } from './partner-ads';
import { makeTargetCircleUrl } from './targetcircle';

const findAffiliateForUrl = (url, affiliates) => {
  const { hostname } = new URL(url);
  const hostWithoutWww = hostname.includes('www')
    ? hostname.substr(4, hostname.length)
    : hostname;
  return affiliates.filter(
    aff => aff.merchantUrl.toLowerCase() === hostWithoutWww,
  )[0];
};

const makeAffiliateUrl = (url, affiliates, customString) => {
  if (!url) {
    return;
  }

  // if (isXlife(url)) {
  //   return makeXLifeUrl(url, customString);
  // }

  const aff = findAffiliateForUrl(url, affiliates);

  if (!aff) {
    return url;
  }
  const { affiliateProgram, merchantIdentifier, customDomain } = aff;

  let affiliateUrl = '';
  switch (affiliateProgram.name.toLowerCase()) {
    case 'adtraction':
      affiliateUrl = makeAdtractionUrl({
        channel: affiliateProgram.affiliateId,
        program: merchantIdentifier,
        productUrl: url,
        epi: customString,
        customDomain,
      });
      break;
    case 'partner-ads':
    case 'partner ads':
      affiliateUrl = makePartnerAdsUrl(
        affiliateProgram.affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'targetcircle':
      affiliateUrl = makeTargetCircleUrl(
        affiliateProgram.affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'adrecord':
      affiliateUrl = makeAdrecordUrl(
        affiliateProgram.affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'tradetracker':
      affiliateUrl = makeTradetrackerUrl({
        channel: affiliateProgram.affiliateId,
        program: merchantIdentifier,
        productUrl: url,
        epi: customString,
        customDomain,
      });
      break;
    case 'tradedoubler':
      affiliateUrl = makeTradedoublerUrl(
        affiliateProgram.affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'awin':
      affiliateUrl = makeAwinUrl(
        affiliateProgram.affiliateId,
        merchantIdentifier,
        url,
        customString,
      );
      break;
    case 'daisycon':
      affiliateUrl = makeDaisyconUrl(url, customDomain, customString);
      break;
    default:
      break;
  }
  return affiliateUrl;
};

export default makeAffiliateUrl;
export { findAffiliateForUrl };
