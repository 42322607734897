// https://www.awin1.com/cread.php
// ?awinmid=18620
// &awinaffid=630299
// &clickref=min+klikk
// &ued=https%3A%2F%2Fwww.outnorth.no%2Furberg%2F2-person-dome-tent-g3%23KombuGreen

// ued=https%3A%2F%2Fwww.outnorth.no%2Furberg%2F2-person-dome-tent-g3%23BlackIris

/**
 * Creates a url for a tradedoubler merchant
 *
 * @param {string | number} channel - channel id
 * @param {string | number} program - program / merchant id
 * @param {string } productUrl - Url to the product to promote
 * @param {string=} epi - custom string used for A/B testing etc
 *
 * @returns {string} - a url used as a href attribute
 */
const makeAwinUrl = (channel, program, productUrl, epi) => {
  if (!channel || !program || !productUrl) {
    throw new Error('Missing input');
  }
  const base = 'https://www.awin1.com/cread.php';
  const programString = `awinmid=${program}`;
  const channelString = `&awinaffid=${channel}`;
  const epiString = epi ? `&clickref=${epi}` : '';
  const productString = `&ued=${encodeURIComponent(productUrl)}`;
  return `${base}?${programString}${channelString}${epiString}${productString}`;
};

export { makeAwinUrl };
