import React from 'react';
import { Button } from '@mui/material';
import { openTab } from '../../utils/open-tab';
import { resources } from '../../resources';
import styled from '@emotion/styled';

export const MahButton = ({ url, ...props }) => (
  <SButton
    color="primary"
    variant="contained"
    onClick={e => openTab(e, url)}
    {...props}
  >
    {resources.mainCta}
  </SButton>
);

const SButton = styled(Button)`
  font-size: 1rem;
`;
