import React from 'react';
import customIdFromJsx from './customIdFromJsx';
import makeAffiliateUrl from '../util/makeAffiliateUrl';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Heading2 } from '../components/common/Heading2';
import ContentfulProductReview from '../components/ContentfulProductReview';
import { options } from './richTextOptions';
const CONTENTFULPRODUCTREVIEW = 'ContentfulProductReview';

export const isProductReview = type => {
  return type === CONTENTFULPRODUCTREVIEW;
};

export const getFirstProduct = (
  contentReferences,
  affiliatedMerchants,
  epiId,
) => {
  try {
    const { product } = contentReferences
      .filter(ref => isProductReview(ref.__typename))
      .filter(p => p.active && p.product)[0];
    const fixed = product.imageReference && product.imageReference.image;
    const url = product.url && product.url.url;
    const affiliateUrl = makeAffiliateUrl(
      url,
      affiliatedMerchants,
      epiId || product.name,
    );

    return {
      image: fixed,
      affiliateUrl,
    };
  } catch {
    return null;
  }
};

export const productHelper = (
  contentReferences,
  affiliatedMerchants,
  epiId,
) => {
  return contentReferences
    .filter(ref => isProductReview(ref.__typename))
    .filter(p => p.active && p.product)
    .map(p => {
      const { product, richTextBlock, id } = p;
      const fixed =
        p.product && p.product.imageReference && p.product.imageReference.image;
      const url = product && product.url && product.url && product.url.url;
      const idForSlug = richTextBlock
        ? customIdFromJsx(
            renderRichText(richTextBlock).find(block => block.type === 'h2'),
          )
        : null;
      return {
        image: fixed,
        affiliateUrl: makeAffiliateUrl(
          url,
          affiliatedMerchants,
          epiId || product.name,
        ),
        id: idForSlug,
        name: product.name,
        key: id,
      };
    });
};

export const refToContentfulProductReview = (ref, key, affiliatedMerchants) => {
  const { richTextBlock, product = {}, id } = ref;
  const fixed =
    product && product.imageReference && product.imageReference.image;
  const url = product && product.url && product.url && product.url.url;
  const imageAltTag =
    product && product.imageReference && product.imageReference.altTag;
  const richText = richTextBlock
    ? renderRichText(richTextBlock, options).map(block => {
        if (block.type === 'h2') {
          return (
            <Heading2 key={id} id={customIdFromJsx(block)}>
              {block.props.children}
            </Heading2>
          );
        }
        return block;
      })
    : null;
  if (!richText) return null;

  return (
    <ContentfulProductReview
      number={key}
      key={id}
      content={richText}
      image={fixed}
      imageAltTag={imageAltTag}
      affiliateUrl={makeAffiliateUrl(url, affiliatedMerchants, product?.name)}
      imageAffiliateUrl={makeAffiliateUrl(
        url,
        affiliatedMerchants,
        product?.name,
      )}
    />
  );
};
