// https://jdt8.net/c/?si=17198&li=1743123&wi=355262
// https://ndt5.net/c/?si=14652&li=1630233&wi=355262
const daisyconUrlHelper = fullUrl => {
  const relativeUrl = fullUrl.replace(/^(?:\/\/|[^/]+)*\//, '');
  return encodeURIComponent(`${relativeUrl}`);
};
/**
 * Creates a url for a daisycon merchant
 *
 * @param {string } productUrl - Url to the product to promote
 * @param {string } customDomain - Base Url used for trackign by daisycon
 * @param {string } epi - custom string used for A/B testing etc
 *
 * @returns {string} - a url used as a href attribute
 */
const makeDaisyconUrl = (productUrl, customDomain, epi) => {
  if (!customDomain || !productUrl) {
    throw new Error('Missing input');
  }
  // need to remove domain from productUrl
  // and only send the path urlEncoded to daisycon in the dl parameter

  const relativeEncodedProductUrl = daisyconUrlHelper(productUrl);

  const epiString = epi ? `&ws=${encodeURIComponent(epi)}` : '';
  const productString = `&dl=${relativeEncodedProductUrl}`;
  return `${customDomain}${epiString}${productString}`;
};

export { makeDaisyconUrl };
