import React from 'react';
import styled from '@emotion/styled';
import { useMediaQuery, Button } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
const SButton = styled(Button)`
  && {
    color: #fff;
    padding: 22px 0;
    &:disabled {
      color: grey;
    }
  }
`;
const ProductStepperButton = ({ onClick, disabled, children, ...rest }) => (
  <SButton size="small" onClick={onClick} disabled={disabled} {...rest}>
    {children}
  </SButton>
);

const StepperButton = ({ onClick, activeStep, label, left, maxSteps }) => {
  const matchesLess = useMediaQuery('(min-width:650px');

  return (
    <ProductStepperButton
      onClick={onClick}
      disabled={left ? activeStep === 0 : activeStep === maxSteps - 1}
      aria-label={label}
    >
      {left ? (
        <>
          <SKeyboardArrowLeftIcon />
          {matchesLess && label}
        </>
      ) : (
        <>
          {matchesLess && label}
          <SKeyboardArrowRightIcon />
        </>
      )}
    </ProductStepperButton>
  );
};
const LeftButton = ({ onClick, activeStep, label }) => (
  <StepperButton onClick={onClick} activeStep={activeStep} label={label} left />
);
const RightButton = ({ onClick, activeStep, label, maxSteps }) => (
  <StepperButton
    onClick={onClick}
    activeStep={activeStep}
    label={label}
    left={false}
    maxSteps={maxSteps}
  />
);

export { LeftButton, RightButton };

const SKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)`
  font-size: 40px;
`;
const SKeyboardArrowLeftIcon = styled(KeyboardArrowLeftIcon)`
  font-size: 40px;
`;
